// #region License
/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */
// #endregion
import * as React from 'react';

import { Router, Route } from 'react-router-dom';

import { StoreProvider, configureStore } from '@jvs-group/jvs-mairistem-store';

const store = configureStore(
  {
    navigation: {
      data: {
        domain: {
          color: 'orange',
        },
      },
    },
  },
  'annotations',
);

// eslint-disable-next-line @typescript-eslint/naming-convention,react/prop-types
const Context = ({ children, path, history }) => (
  <StoreProvider store={store}>
    <Router history={history}>
      <Route path={path}>
        {(React.isValidElement(children)
          ? children
          : React.createElement(children))}
      </Route>
    </Router>
  </StoreProvider>

);

export default Context;
