import * as React from 'react';

import {
  App, Feature, Menu, render,
} from '@jvs-group/jvs-mairistem-module';
import { request } from '@jvs-group/jvs-mairistem-store';

import Context from '@/Context';

const Gestionnaire = React.lazy(
  () => import('./components/Gestionnaire'),
);

render(
  <App
    group="administres"
    name="Annotations"
    code="HOL_GAN"
    request={request}
    context={Context}
  >
    <Menu.Home>
      <Feature>
        {/* Ugly hack to kind of have a redirection */}
        {/* We're instanciating because it simply doesn't work otherwise */}
        <Feature.Content><Gestionnaire /></Feature.Content>
      </Feature>
    </Menu.Home>
    <Menu name="Gestionnaire" icon="edit" path="/gestionnaire">
      <Feature>
        <Feature.Content>{Gestionnaire}</Feature.Content>
      </Feature>
    </Menu>
  </App>,
);
